import AuthBase from "../AuthBase";
import {useContext, useEffect, useRef, useState} from "react";
import useCallDataApi from "../../hooks/data";
import {useHistory, useParams} from "react-router-dom";
import {
    Avatar,
    FormControlLabel,
    FormGroup,
    Grid,
    InputAdornment,
    NativeSelect,
    Stack,
    Switch,
    TextField,
    useTheme
} from "@mui/material";
import ConfigContext from "../../context/ConfigContext";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import Button from "@mui/material/Button";
import FileUploadRoundedIcon from '@mui/icons-material/FileUploadRounded';
import Box from "@mui/material/Box";
import SaveRoundedIcon from '@mui/icons-material/SaveRounded';
import userTemplate from "./UserTemplate";
import ModeEditOutlineRoundedIcon from "@mui/icons-material/ModeEditOutlineRounded";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import {getFormData} from "../../utils/FormUtils";
import DinoDialog from "../../components/Dialog";
import TimeRangePicker from "../../components/TimeRangePicker";
import workHourTemplate from "../opening-hours/WorkHourTemplate";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import AuthContext from "../../context/AuthContext";
import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";
import * as React from "react";
import thousandSeparator from "../../utils/numberUtils";
import _ from "lodash";
import {isUnderMaxFileSize} from "../../utils/ValidationUtils";



const ColleagueDetail = () => {
    const theme = useTheme()
    const {baseUrl} = useContext(ConfigContext)
    const [colleague, setColleague] = useState(userTemplate)
    const [editedColleague, setEditedColleague] = useState(userTemplate)
    const [colleagueRole, setColleagueRole] = useState('')
    const [openingHours, setOpeningHours] = useState(workHourTemplate)
    const [editedOpeningHours, setEditedOpeningHours] = useState(workHourTemplate)
    const [prices, setPrices] = useState([])
    const [selectableServices, setSelectableServices] = useState([])
    const [leaves, setLeaves] = useState([])
    const [services, setServices] = useState([])
    const [leaveStart, setLeaveStart] = useState(new Date())
    const [leaveEnd, setLeaveEnd] = useState(new Date())
    const [deleteLeaveOpen, setDeleteLeaveOpen] = useState(false)
    const [priceDeleteDialog, setPriceDeleteDialog] = useState(false)
    const [priceEditDialog, setPriceEditDialog] = useState(false)
    const [colleagueDeleteDialog, setColleagueDeleteDialog] = useState(false)
    const [colleagueDeleteText, setColleagueDeleteText] = useState('')
    const [pp, setPp] = useState('')

    const [current, setCurrent] = useState(-1)

    const {getData: fetchColleague, formDataUpdateData: updateColleague, deleteData: deleteColleague, postData: postColleague} = useCallDataApi('colleague')
    const {getData: fetchPrice, createData: createPrice, deleteData: deletePrice, updateData: updatePrice} = useCallDataApi('price')
    const {getData: fetchService} = useCallDataApi('service')
    const {getData: fetchLeave, deleteData: deleteLeave, createData: createLeave} = useCallDataApi('leave')
    const {getData: fetchOpeningHours, updateData: updateOpeningHours} = useCallDataApi('work-hour')
    const params = useParams()
    const newPriceForm = useRef(null)
    const priceEditForm = useRef(null)
    const leaveForm = useRef(null)
    const history = useHistory()
    const {user, role} = useContext(AuthContext)
    const {MAX_IMG_SIZE} = useContext(ConfigContext)

    const newPrice = () => {
        const isValid = newPriceForm.current.checkValidity()
        newPriceForm.current.reportValidity()
        if (!isValid) return;
        const data = getFormData(newPriceForm.current)
        createPrice({...data, colleague: params.id}).then(p => setPrices([...prices, p]))
    }

    const editPrice = () => {
        const isValid = priceEditForm.current.checkValidity()
        priceEditForm.current.reportValidity()
        if (!isValid) return;
        const data = getFormData(priceEditForm.current)
        updatePrice(`${current}/`, data).then(p => setPrices([...prices.filter(pr => pr.id !== p.id), p]))
        setPriceEditDialog(false)
    }

    const removePrice = () => {
        deletePrice(current).then(() => setPrices(prices.filter(p => p.id !== current)))
    }

    const getPrice = (id) => {
        return prices.find(p => p.id === id)
    }

    const getService = (id) => {
        return services.find(s => s.id === id)
    }

    const isDifferent = () => {
        return JSON.stringify(editedColleague) !== JSON.stringify(colleague)
    }

    const isDifferentOpeningHours = () => {
        return JSON.stringify(editedOpeningHours) !== JSON.stringify(openingHours)
    }

    useEffect(() => {
        fetchPrice(`get_for_colleague?colleague=${params?.id}`).then(p => setPrices(p))
        fetchColleague(params?.id).then(c => {
            setColleague(c)
            setEditedColleague(c)
        })
        fetchColleague(`get_role_for_colleague/?colleague=${params?.id}`).then(r => setColleagueRole(r.role))
        fetchLeave(`${params?.id}/get_for_colleague`).then(l => setLeaves(l))
        fetchOpeningHours(`${params?.id}/get_for_user`).then(w => {
            setOpeningHours(w)
            setEditedOpeningHours(_.cloneDeep(w))
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        fetchService(`get_for_business`).then(allServices => {
            setServices(allServices)
            const usedServices = prices.map(p => p.service)
            setSelectableServices(allServices.filter(s => !usedServices.includes(s.id)))
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [prices])

    const greyText = {color: '#536B74'}
    const titleCaption = {color: theme.palette.primary[500]}

    const buttons = [{
        name: 'Változtatások mentése',
        props: {
            endIcon: <SaveRoundedIcon/>,
            disabled: !isDifferent(),
            variant: 'contained',
            onClick: () => {
                if (!(editedColleague?.profile_picture instanceof File)) delete editedColleague['profile_picture']
                updateColleague(colleague.id, editedColleague).then(c => {
                    setColleague(c)
                    setEditedColleague(c)
                })
            }
        }
    }]

    const deleteIconButtonStyle = {
        alignSelf: 'center',
        color: theme.palette.primary[400],
        border: '2px solid',
        borderColor: theme.palette.primary[400]
    }

    const addNewOpeningHour = (day) => {
        editedOpeningHours[day] = [...editedOpeningHours[day], {start: '09:00', end: '21:00'}]
        setEditedOpeningHours({...editedOpeningHours})
    }

    const removeOpeningHour = (day, idx) => {
        editedOpeningHours[day].splice(idx, 1)
        setEditedOpeningHours({...editedOpeningHours})
    }

    const editOpeningHour = (start, end, day, idx) => {
        editedOpeningHours[day] = [...editedOpeningHours[day].map((h, i) => {
            if (i === idx) return {start: start, end: end}
            else return h
        })]
        setEditedOpeningHours({...editedOpeningHours})
    }

    const addLeave = () => {
        const data = getFormData(leaveForm.current)
        createLeave({user: params.id, ...data}).then(l => setLeaves([...leaves, l]))
    }

    const getProfilePic = () => {
        if (pp instanceof File) return URL.createObjectURL(pp)
        if (colleague?.profile_picture?.startsWith('http')) return colleague.profile_picture
        if (colleague?.profile_picture === '') return ''
        return baseUrl + colleague?.profile_picture;
    }

    const changeRole = (event) => {
        if (colleagueRole === 'owner') return
        const newValue = event.target.checked ? 'manager' : 'employee'
        postColleague('change_role/', {colleague: colleague.id, role: newValue}).then(r => setColleagueRole(r.role))
    }

    const deleteActions = <>
        <Button variant='outlined' style={{padding: 15}} onClick={() => setColleagueDeleteDialog(false)}>mégsem</Button>
        <Button variant='contained' disabled={colleagueDeleteText.toUpperCase() !== 'TÖRLÉS'} style={{padding: 15}} onClick={() => {
            setColleagueDeleteDialog(false)
            deleteColleague(params.id).then(() => history.push('/colleagues'))
        }} endIcon={<DeleteRoundedIcon/>}>Törlés</Button>
    </>

    const handleLeaveStartChange = (start) => {
        setLeaveStart(start)
        if (leaveEnd && start > leaveEnd) setLeaveEnd(start)
    }

    const handleLeaveEndChange = (end) => {
        setLeaveEnd(end)
        if (leaveStart && end < leaveStart) setLeaveStart(end)
    }

    // noinspection JSValidateTypes
    return <AuthBase label={colleague?.name} buttons={buttons} filterButtonsMobile={buttons}>
        <div className="main">
            <Button variant='text' startIcon={<ArrowBackIosNewRoundedIcon/>} onClick={() => history.push(`/colleagues`)}>vissza</Button>
            <Grid container spacing={2}>

                {/*Headline*/}
                <Grid item xs={12}>
                    <div className="white-card">
                        <Stack direction='row' style={{justifyContent: 'space-between'}}>
                            <Stack direction='row' spacing={2}>
                                <Avatar src={getProfilePic()} sx={{width: 90, height: 90}}/>
                                <Stack spacing={1} style={{alignSelf: 'center'}}>
                                    <Typography variant='headline6'>{colleague?.name}</Typography>
                                    <Typography variant='body1' style={greyText}>{colleague?.title}</Typography>
                                </Stack>
                            </Stack>
                            <IconButton
                                sx={{display: (user.user_id === parseInt(params.id) || colleagueRole === 'owner') ? "none" : "block", width: 50, height: 50, color: theme.palette.primary[400], alignSelf: 'center'}}
                                onClick={() => {
                                    setColleagueDeleteDialog(true)
                                }}>
                                <DeleteIcon/>
                            </IconButton>
                        </Stack>
                    </div>
                </Grid>

                {/*General data*/}
                <Grid item xs={12} md={6}>
                    <div className="white-card">
                        <Typography variant='subtitle1'>Általános adatok</Typography>
                        <Typography variant='body2' style={greyText}>Add meg a kolléga adatait!</Typography>
                        <form style={{marginTop: 10}}>
                            <Stack spacing={1}>
                                <Typography variant='caption' style={titleCaption}>Keresztnév</Typography>
                                <TextField fullWidth variant='standard' onChange={event => setEditedColleague({...editedColleague, first_name: event.target.value})} name='first_name' value={editedColleague?.first_name}/>

                                <Typography variant='caption' style={titleCaption}>Vezetéknév</Typography>
                                <TextField fullWidth variant='standard' onChange={event => setEditedColleague({...editedColleague, last_name: event.target.value})} name='last_name' value={editedColleague?.last_name}/>

                                <Typography variant='caption' style={titleCaption}>Titulus</Typography>
                                <TextField fullWidth variant='standard' onChange={event => setEditedColleague({...editedColleague, title: event.target.value})} name='title' value={editedColleague?.title}/>

                                <FormGroup sx={{display: (colleagueRole === 'owner' || role === 'employee' || user.user_id === parseInt(params.id)) ? 'none' : 'block'}}>
                                    <FormControlLabel control={<Switch checked={colleagueRole === 'manager'} onChange={changeRole} />} label={colleagueRole === 'employee' ? 'Alkalmazott' : 'Menedzser'} />
                                </FormGroup>
                            </Stack>
                        </form>
                    </div>
                </Grid>

                {/*Profile picture*/}
                <Grid item xs={12} md={6}>
                    <div className="white-card">
                        <Typography variant='subtitle1'>Kolléga profilképe</Typography>
                        <Typography variant='body2' style={greyText}>Töltsd fel a kolléga profilképét! (Ez a kép fog
                            megjelenni a foglalási felületen is!)</Typography>
                        <Stack direction='row' spacing={2} alignItems='center' style={{marginTop: 10}}>
                            <Avatar src={getProfilePic()} sx={{width: 80, height: 80, background: theme.palette.primary[50], border: '2px solid', borderColor: theme.palette.primary[500]}} />
                            <Button variant='outlined' component='label' endIcon={<FileUploadRoundedIcon/>}>Kép feltöltése <input type="file" accept='image/*' hidden onChange={event => {
                                const file = event.target.files[0];
                                if (isUnderMaxFileSize(file, MAX_IMG_SIZE)) {
                                    // noinspection JSCheckFunctionSignatures
                                    setPp(file)
                                    // noinspection JSCheckFunctionSignatures
                                    setEditedColleague({...editedColleague, profile_picture: event.target.files[0]})
                                }
                                else alert(`A kép nem lehet nagyobb mint ${MAX_IMG_SIZE} MB`)
                            }}/></Button>
                        </Stack>
                    </div>
                </Grid>

                {/*Services*/}
                <Grid item xs={12} md={12}>
                    <div className="white-card">
                        <Typography variant='subtitle1'>Szolgáltatások</Typography>
                        <Typography variant='body2' color='#536B74'>Add meg kolléga által nyújtott szolgáltatásokat!</Typography>
                        <Stack spacing={2} marginTop={2}>
                            {prices.map((p, i) => <Stack direction='row' key={`price_${i}`} justifyContent="space-between">
                                <Stack direction='row' spacing={2} alignItems='center'>
                                    <Avatar src={baseUrl + getService(p?.service)?.image}
                                            sx={{width: '48px', height: '48px'}}/>
                                    <Stack>
                                        <Typography variant='subtitle2'>{getService(p?.service)?.name}</Typography>
                                        <Typography variant='caption'
                                                    color='#536B74'>{getService(p?.service)?.length} perc</Typography>
                                    </Stack>
                                </Stack>
                                <Stack direction='row' spacing={1} alignItems='center'>
                                    <Typography variant='caption' color='#2F5C43'>{thousandSeparator(p.price)} Ft</Typography>
                                    <IconButton onClick={() => {
                                        setCurrent(p.id)
                                        setPriceEditDialog(true)
                                    }} sx={{
                                        border: '2px solid',
                                        borderColor: theme.palette.primary[500],
                                        background: '#fff',
                                        color: theme.palette.primary[500]
                                    }}><ModeEditOutlineRoundedIcon/></IconButton>
                                    <IconButton onClick={() => {
                                        setCurrent(p.id)
                                        setPriceDeleteDialog(true)
                                    }} sx={{
                                        border: '2px solid',
                                        borderColor: theme.palette.primary[500],
                                        background: '#fff',
                                        color: theme.palette.primary[500]
                                    }}><DeleteRoundedIcon/></IconButton>
                                </Stack>
                            </Stack>)}
                        </Stack>
                        <form ref={newPriceForm}>
                            <Stack direction={{xs: 'column', sx: 'row', md: 'row'}} marginTop={2} spacing={2}>

                                <Stack spacing={1}>
                                    <Typography variant='caption' color={theme.palette.primary[500]}>Szolgáltatás</Typography>
                                    <NativeSelect required inputProps={{name: 'service', id: 'uncontrolled-service',}}>
                                        {selectableServices.map((s, i) => <option key={`service_${i}`} value={s?.id}>{s?.name}</option>)}
                                    </NativeSelect>
                                </Stack>
                                <Stack spacing={1}>
                                    <Typography variant='caption' color={theme.palette.primary[500]}>Ár</Typography>
                                    <TextField variant='standard' required type='number' name='price' InputProps={{endAdornment: <InputAdornment position="end">Ft</InputAdornment>}}/>
                                </Stack>

                                <Button variant='contained' disabled={selectableServices.length === 0} onClick={newPrice} sx={{alignSelf: 'end'}} endIcon={<AddCircleIcon/>}>Új szolgáltatás hozzáadása</Button>
                            </Stack>
                        </form>
                    </div>
                </Grid>

                {/*Work hours*/}
                <Grid item xs={12} md={12}>
                    <div className="white-card">
                        <Typography variant='subtitle1'>Munkaidő beállítása</Typography>
                        <Typography variant='body2' style={greyText}>Add meg mikor dolgozik a kolléga! Figyelj, hogy a
                            munkaidők a nyitvatartási időbe essenek bele!</Typography>
                        <Typography variant='body2' color='#ff0000'>
                            Ez a menüpont az adott kolléga elérhetőségi idejét állítja a foglaló felületen, amennyiben a szalonod/boltod nyitvatartását szeretnéd módosítani a lábléc szekcióban azt a <a href='/opening-hours'>nyitvatartás</a> menüpontban tudod megtenni.
                        </Typography>
                        <Grid container spacing={2} marginTop={3}>
                            <Grid item xs={12} md={12 / 5}>
                                <Typography variant='subtitle2'>Hétfő</Typography>
                            </Grid>
                            {editedOpeningHours?.monday?.map((h, i) => <Grid key={`monday_${i}_${h.start}_${h.end}`} item xs={12} md={12 / 5}>
                                <TimeRangePicker startTime={h.start} endTime={h.end} day='monday' idx={i} editing={editOpeningHour} deleting={removeOpeningHour}/>
                            </Grid>)}
                            <Grid item xs={12} md={12 / 5}>
                                <Button endIcon={<AddCircleOutlineIcon/>} onClick={() => addNewOpeningHour('monday')} variant='outlined' size='small'>Új idősáv hozzáadása</Button>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} marginTop={3}>
                            <Grid item xs={12} md={12 / 5}>
                                <Typography variant='subtitle2'>Kedd</Typography>
                            </Grid>
                            {editedOpeningHours?.tuesday?.map((h, i) => <Grid key={`tuesday_${i}_${h.start}_${h.end}`} item xs={12} md={12 / 5}>
                                <TimeRangePicker startTime={h.start} endTime={h.end} day='tuesday' idx={i} editing={editOpeningHour}  deleting={removeOpeningHour}/>
                            </Grid>)}
                            <Grid item xs={12} md={12 / 5}>
                                <Button endIcon={<AddCircleOutlineIcon/>} onClick={() => addNewOpeningHour('tuesday')} variant='outlined' size='small'>Új idősáv hozzáadása</Button>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} marginTop={3}>
                            <Grid item xs={12} md={12 / 5}>
                                <Typography variant='subtitle2'>Szerda</Typography>
                            </Grid>
                            {editedOpeningHours?.wednesday?.map((h, i) => <Grid key={`wednesday_${i}_${h.start}_${h.end}`} item xs={12} md={12 / 5}>
                                <TimeRangePicker startTime={h.start} endTime={h.end} day='wednesday' idx={i} editing={editOpeningHour}  deleting={removeOpeningHour}/>
                            </Grid>)}
                            <Grid item xs={12} md={12 / 5}>
                                <Button endIcon={<AddCircleOutlineIcon/>} onClick={() => addNewOpeningHour('wednesday')} variant='outlined' size='small'>Új idősáv hozzáadása</Button>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} marginTop={3}>
                            <Grid item xs={12} md={12 / 5}>
                                <Typography variant='subtitle2'>Csütörtök</Typography>
                            </Grid>
                            {editedOpeningHours?.thursday?.map((h, i) => <Grid key={`thursday_${i}_${h.start}_${h.end}`} item xs={12} md={12 / 5}>
                                <TimeRangePicker startTime={h.start} endTime={h.end} day='thursday' idx={i} editing={editOpeningHour}  deleting={removeOpeningHour}/>
                            </Grid>)}
                            <Grid item xs={12} md={12 / 5}>
                                <Button  endIcon={<AddCircleOutlineIcon/>} onClick={() => addNewOpeningHour('thursday')} variant='outlined' size='small'>Új idősáv hozzáadása</Button>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} marginTop={3}>
                            <Grid item xs={12} md={12 / 5}>
                                <Typography variant='subtitle2'>Péntek</Typography>
                            </Grid>
                            {editedOpeningHours?.friday?.map((h, i) => <Grid key={`friday_${i}_${h.start}_${h.end}`} item xs={12} md={12 / 5}>
                                <TimeRangePicker startTime={h.start} endTime={h.end} day='friday' idx={i} editing={editOpeningHour}  deleting={removeOpeningHour}/>
                            </Grid>)}
                            <Grid item xs={12} md={12 / 5}>
                                <Button endIcon={<AddCircleOutlineIcon/>} onClick={() => addNewOpeningHour('friday')} variant='outlined' size='small'>Új idősáv hozzáadása</Button>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} marginTop={3}>
                            <Grid item xs={12} md={12 / 5}>
                                <Typography variant='subtitle2'>Szombat</Typography>
                            </Grid>
                            {editedOpeningHours?.saturday?.map((h, i) => <Grid key={`saturday_${i}_${h.start}_${h.end}`} item xs={12} md={12 / 5}>
                                <TimeRangePicker startTime={h.start} endTime={h.end} day='saturday' idx={i} editing={editOpeningHour}  deleting={removeOpeningHour}/>
                            </Grid>)}
                            <Grid item xs={12} md={12 / 5}>
                                <Button endIcon={<AddCircleOutlineIcon/>} onClick={() => addNewOpeningHour('saturday')} variant='outlined' size='small'>Új idősáv hozzáadása</Button>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} marginTop={3}>
                            <Grid item xs={12} md={12 / 5}>
                                <Typography variant='subtitle2'>Vasárnap</Typography>
                            </Grid>
                            {editedOpeningHours?.sunday?.map((h, i) => <Grid key={`sunday_${i}_${h.start}_${h.end}`} item xs={12} md={12 / 5}>
                                <TimeRangePicker startTime={h.start} endTime={h.end} day='sunday' idx={i} editing={editOpeningHour}  deleting={removeOpeningHour}/>
                            </Grid>)}
                            <Grid item xs={12} md={12 / 5}>
                                <Button endIcon={<AddCircleOutlineIcon/>} onClick={() => addNewOpeningHour('sunday')} variant='outlined' size='small'>Új idősáv hozzáadása</Button>
                            </Grid>
                        </Grid>
                        <Stack direction='row' marginTop={2} justifyContent='end'>
                            <Button size='large' variant='contained' endIcon={<SaveRoundedIcon/>} disabled={!isDifferentOpeningHours()} onClick={() => updateOpeningHours(`${params.id}/update_user/`, editedOpeningHours).then(o => {
                                setOpeningHours(o)
                                setEditedOpeningHours(_.cloneDeep(o))
                            })}>Változtatások mentése</Button>
                        </Stack>
                    </div>
                </Grid>

                {/*Leaves*/}
                <Grid item xs={12} md={12}>
                    <div className="white-card">
                        <Typography variant='subtitle1'>Saját szabadságok beállítása</Typography>
                        <Typography variant='body2' style={greyText}>Adj hozzá szabadságokat! A kollégák egyedi szabadságát a kollégák menüpont alatt tudod beállítani!</Typography>
                        {leaves.length === 0 && <Box sx={{border: '1px solid', borderColor: '#F1F4F5', borderRadius: '16px', marginTop: 2}}>
                            <Stack spacing={2} alignItems='center' justifyContent='center' sx={{padding: 2}}>
                                <Typography variant='subtitle2' color='#6F8E9A'>Nincsenek szabadságok</Typography>
                                <Typography variant='body2' color='#6F8E9A'>Adj hozzá új szabadságot az alábbi gombra kattintva! 👇</Typography>
                            </Stack>
                        </Box>}
                        {leaves.map((l, i) => <Grid container spacing={2} key={`leave_${i}`} sx={{marginTop: 2}}>
                            <Grid item xs={12} md={3}><Typography variant='subtitle2'>{l?.name}</Typography></Grid>
                            <Grid item xs={12} md={3}><Typography variant='button' sx={{color: '#536B74'}}>{l?.from_date} - {l?.end_date}</Typography></Grid>
                            <Grid item xs={12} md={3}><Typography variant='caption' sx={{color: '#38474D'}}>{l?.business ? 'Céges szabadság' : 'Saját szabadság'}</Typography></Grid>
                            <Grid item xs={12} md={3} sx={{display: l?.business ? 'none' : 'block'}}>
                                <IconButton style={{...deleteIconButtonStyle, alignSelf: 'center'}} onClick={() => {
                                    setCurrent(l.id)
                                    setDeleteLeaveOpen(true)
                                }}>
                                    <DeleteIcon/>
                                </IconButton>
                            </Grid>
                        </Grid>)}
                        <form ref={leaveForm}>
                            <Grid container spacing={1} marginTop={3}>
                                <Grid item xs={12} md={3}><Stack spacing={1}><Typography variant='caption' color={theme.palette.primary[500]}>Szabadság címe</Typography><TextField name='name' variant='standard'/></Stack></Grid>
                                <Grid item xs={12} md={3}><Stack spacing={1}><Typography variant='caption' color={theme.palette.primary[500]}>Szabadság kezdete</Typography><LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        value={leaveStart}
                                        onChange={(newValue) => handleLeaveStartChange(newValue)}
                                        minDate={new Date()}
                                        inputFormat="YYYY-MM-DD"
                                        renderInput={(params) => <TextField name='from_date' variant='standard' {...params} />}
                                    />
                                </LocalizationProvider></Stack></Grid>
                                <Grid item xs={12} md={3}><Stack spacing={1}><Typography variant='caption' color={theme.palette.primary[500]}>szabadság vége</Typography><LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        value={leaveEnd}
                                        onChange={(newValue) => handleLeaveEndChange(newValue)}
                                        minDate={leaveStart}
                                        inputFormat="YYYY-MM-DD"
                                        renderInput={(params) => <TextField name='end_date' variant='standard' {...params} />}
                                    />
                                </LocalizationProvider></Stack></Grid>
                                <Grid item xs={12} md={3} sx={{display: 'flex', alignItems: 'end'}}><Button variant='contained' size='small' endIcon={<AddCircleOutlineIcon/>} onClick={addLeave}>Új szabadság hozzáadása</Button></Grid>
                            </Grid>
                        </form>
                    </div>
                </Grid>
            </Grid>
        </div>

        <DinoDialog open={deleteLeaveOpen}
                    title='Szabadság törlése'
                    subtitle='Biztos vagy benne hogy szeretnéd törölni a szabadságot?'
                    handleClose={() => setDeleteLeaveOpen(false)}>
            <Stack direction='row' justifyContent='end' spacing={1} marginTop={3}>
                <Button variant='outlined' onClick={() => setDeleteLeaveOpen(false)}>Mégsem</Button>
                <Button variant='contained' onClick={() => {
                    setDeleteLeaveOpen(false)
                    deleteLeave(current).then(() => setLeaves([...leaves.filter(l => l.id !== current)]))
                }} endIcon={<DeleteRoundedIcon/>}>Törlés</Button>
            </Stack>
        </DinoDialog>

        <DinoDialog open={priceDeleteDialog}
                    title='Ár törlése'
                    subtitle='Ha kitörlöd az árat a kliensek számára nem fog megjelenni a foglalási folyamat során!'
                    handleClose={() => setPriceDeleteDialog(false)}>
            <Stack direction='row' justifyContent='end' spacing={1} marginTop={3}>
                <Button variant='outlined' onClick={() => setPriceDeleteDialog(false)}>Mégsem</Button>
                <Button variant='contained' onClick={() => {
                    setPriceDeleteDialog(false)
                    removePrice()
                }} endIcon={<DeleteRoundedIcon/>}>Törlés</Button>
            </Stack>
        </DinoDialog>

        <DinoDialog open={priceEditDialog}
                    title='Ár szerkesztése'
                    subtitle='Add meg az új árat!'
                    handleClose={() => setPriceEditDialog(false)}>
            <form ref={priceEditForm}>
                <Stack spacing={1}>
                    <Typography variant='caption' color={theme.palette.primary[500]}>Ár</Typography>
                    <TextField required variant='standard' name='price' defaultValue={getPrice(current)?.price}/>
                </Stack>
            </form>

            <Stack direction='row' justifyContent='end' spacing={1} marginTop={3}>
                <Button variant='outlined' onClick={() => setPriceEditDialog(false)}>Mégsem</Button>
                <Button variant='contained' onClick={() => {
                    editPrice()
                }} endIcon={<SaveRoundedIcon/>}>Változtatások mentése</Button>
            </Stack>
        </DinoDialog>

        <DinoDialog open={colleagueDeleteDialog}
                    title='Kolléga törlése'
                    subtitle='Minden hozzá tartozó adat el fog veszni, foglalások, árak, stb...!'
                    handleClose={() => setColleagueDeleteDialog(false)}
                    actions={deleteActions}>
            <form>
                <Stack spacing={1}>
                    <Typography variant='caption'>Ha biztosan törölni szeretné a kollégát, gépelje be a "törlés" szót</Typography>
                    <TextField variant='standard' name='delete' value={colleagueDeleteText} onChange={e => setColleagueDeleteText(e.target.value)}/>
                </Stack>
            </form>
        </DinoDialog>

    </AuthBase>
}

export default ColleagueDetail