import AuthBase from "../AuthBase";
import {Avatar, Grid, Stack, useTheme} from "@mui/material";
import Typography from "@mui/material/Typography";
import {Doughnut, Bar} from 'react-chartjs-2';
import {Chart as ChartJS, ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement, Title} from 'chart.js';
import {useContext, useEffect, useState} from "react";
import AuthContext from "../../context/AuthContext";
import useCallDataApi from "../../hooks/data";
import Loading from "../../components/Loading";
import DinoDataGrid from "../../components/DataGrid";
import {GridActionsCellItem} from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";
import KeyboardArrowRightRoundedIcon from "@mui/icons-material/KeyboardArrowRightRounded";
import Button from "@mui/material/Button";
import CalendarTodayRoundedIcon from "@mui/icons-material/CalendarTodayRounded";
import DetailDrawer from "../../components/DetailDrawer";
import ReservationDetail from "../reservation/ReservationDetail";
import DinoDialog from "../../components/Dialog";
import DialogDetail from "../reservation/DialogDetail";
import ReserveBase from "../../components/reserve/ReserveBase";
import SelectOnlyDate from "../../components/reserve/SelectOnlyDate";
import thousandSeparator from "../../utils/numberUtils";
import {ReservationProvider} from "../../context/ReservationContext";
import {useSnackbar} from "notistack";
import {downloadFile} from "../../utils/UrlUtils";

const Dashboard = () => {
    const theme = useTheme()
    const snackbar = useSnackbar()
    const [serviceStats, setServiceStats] = useState({})
    const [reservationStats, setReservationStats] = useState([])
    const [reservations, setReservations] = useState([])
    const [modifyTrigger, setModifyTrigger] = useState(0)
    const [detailView, setDetailView] = useState(false)
    const [reservation, setReservation] = useState({})
    const [deleteOpen, setDeleteOpen] = useState(false)
    const [newReservationOpen, setNewReservationOpen] = useState(false)
    const [modifyTimeOpen, setModifyTimeOpen] = useState(false)
    const {getData: fetchServiceSatats} = useCallDataApi('business')
    const {
        getData: fetchReservations,
        getBlob: fetchBlob,
        deleteData: destroyReservation,
        postData: postReservation
    } = useCallDataApi('admin-reservation')
    const {getData: fetchAdminReservations} = useCallDataApi('admin-reservation')
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        const getData = async () => {
            const serviceStats = fetchServiceSatats('get_service_stats/')
            const reservationStats = fetchServiceSatats('get_reservation_stats/')
            const mostRecentReservations = fetchAdminReservations('get_for_business')
            const [s, r, m] = await Promise.all([serviceStats, reservationStats, mostRecentReservations])
            if (s) setServiceStats(s)
            if (r) setReservationStats(r)
            if (m) setReservations(m)
            setLoading(false)
        }
        getData().catch(r => console.log(r))

    }, [])

    const deleteReservation = (id) => {
        destroyReservation(id).then(resp => {
            if (resp.status === 204) setReservations(reservations.filter(r => r.id !== id))
        })
        setDetailView(false)
    }

    const updateDate = (id, date, slot) => {
        if (JSON.stringify(slot) === JSON.stringify({})) return
        postReservation(`${id}/update_date/`, {date: date, slot: slot}).then(r => {
            setReservation(r)
            setReservations([r, ...reservations.filter(res => r.id !== res.id)])
        })
    }

    const findReservation = (id) => {
        return reservations.find(r => r.id === id)
    }

    const accept = (id) => {
        fetchReservations(`${id}/accept/`)
            .then(updatedReservation => setReservations([...reservations.map(
                currentReservation => {
                    if (currentReservation.id === updatedReservation.id) return updatedReservation
                    else return currentReservation
                })
            ]))
        setDetailView(false)
    }

    const reject = (id) => {
        fetchReservations(`${id}/reject/`)
            .then(updatedReservation => setReservations([...reservations.map(
                currentReservation => {
                    if (currentReservation.id === updatedReservation.id) return updatedReservation
                    else return currentReservation
                })
            ]))
        setDetailView(false)
    }

    const create_invoice = (id) => {
        fetchReservations(`${id}/create_invoice/`)
            .then(updatedReservation => setReservations([...reservations.map(
                currentReservation => {
                    if (currentReservation.id === id) return {
                        ...currentReservation,
                        invoice_id: updatedReservation?.invoice_id
                    }
                    else return currentReservation
                })
            ]))
        setDetailView(false)
        snackbar.enqueueSnackbar('Számla elkészítve!', {variant: 'success'})
    }

    const download_invoice = (id) => {
        fetchBlob(`${id}/download_invoice/`)
            .then(response => {
                const url = window.URL.createObjectURL(new Blob([response]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'invoice.pdf');
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
            })
        setDetailView(false)
    }

    const send_invoice = (id) => {
        fetchReservations(`${id}/send_invoice/`)
            .then(sent => snackbar.enqueueSnackbar('Számla kiküldve!', {variant: 'success'}))
        setDetailView(false)
    }

    ChartJS.register(ArcElement, Tooltip, Legend);
    ChartJS.register(
        CategoryScale,
        LinearScale,
        BarElement,
        Title,
    );


    const data = {
        labels: serviceStats?.s,
        datasets: [
            {
                label: 'Foglalások száma',
                data: serviceStats?.r,
                backgroundColor: [
                    'rgba(255, 99, 132, 0.2)',
                    'rgba(54, 162, 235, 0.2)',
                    'rgba(255, 206, 86, 0.2)',
                    'rgba(75, 192, 192, 0.2)',
                    'rgba(153, 102, 255, 0.2)',
                    'rgba(255, 159, 64, 0.2)',
                ],
                borderColor: [
                    'rgba(255, 99, 132, 1)',
                    'rgba(54, 162, 235, 1)',
                    'rgba(255, 206, 86, 1)',
                    'rgba(75, 192, 192, 1)',
                    'rgba(153, 102, 255, 1)',
                    'rgba(255, 159, 64, 1)',
                ],
                borderWidth: 1,
            },
        ],
    };

    const options = {
        responsive: true,
    };
    const labels = ['Január', 'Február', 'Március', 'Április', 'Május', 'Június', 'Július', 'Augusztus', 'Szeptember', 'Október', 'November', 'December'];

    const data2 = {
        labels,
        datasets: [
            {
                label: 'Foglalások',
                data: reservationStats,
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
            },
            // {
            //     label: 'Dataset 2',
            //     data: labels.map(() => 200),
            //     backgroundColor: 'rgba(53, 162, 235, 0.5)',
            // },
        ],
    };
    const {user} = useContext(AuthContext)
    // buttons={[{name: 'ASD', style: {background: theme.palette.primary[500], color: '#fff'}, onClick: () => {console.log('asd')}}]}

    const acceptedStyle = {
        alignSelf: 'center',
        background: theme.palette.primary[300],
        color: '#fff'
    };
    const waitingStyle = {
        alignSelf: 'center',
        background: '#FEF4E6',
        color: '#ED8802'
    };
    const ignoreStyle = {
        alignSelf: 'center',
        background: '#e7e7e7',
        color: '#7c7c7c'
    };

    const iconStyle = {
        color: theme.palette.primary[500],
        padding: 8,
        background: '#fff',
        border: '2px solid',
        borderColor: theme.palette.primary[500],
        borderRadius: '50%',
        width: 42,
        height: 42
    };
    const invertedIconStyle = {
        background: theme.palette.primary[500],
        padding: 8,
        color: '#fff',
        border: '2px solid',
        borderColor: theme.palette.primary[500],
        borderRadius: '50%',
        width: 42,
        height: 42
    };
    const statusMap = {
        'accepted': 'elfogadott',
        'pending': 'elfogadásra vár...',
        'rejected': '',

    }
    const statusStyleMap = {
        'accepted': acceptedStyle,
        'pending': waitingStyle,
        'rejected': ignoreStyle

    }

    const renderName = (params) => <Stack spacing={1} direction='row'>
        <Avatar
            style={statusStyleMap[params.row?.display_status]}>{params.row?.last_name[0]}{params.row?.first_name[0]}</Avatar>
        <Stack spacing={0}>
            <span className='reservation-name'>{params.row?.name}</span>
            <span
                className={params.row?.display_status === 'accepted' ? 'reservation-accepted' : 'reservation-waiting'}>{params.row?.display_status !== 'rejected' && statusMap[params.row?.display_status]}</span>
        </Stack>
    </Stack>

    const renderDate = (params) => <Stack spacing={0}>
        <Typography variant='button' style={{color: '#000'}}
                    align='left'>{params.row?.start_time}-{params.row?.end_time}</Typography>
        <Typography variant='caption' style={{color: '#536B74'}} align='left'>{params.row?.date}</Typography>
    </Stack>

    const columns = [
        {field: 'name', headerName: 'Kliens neve', width: 200, renderCell: renderName},
        {
            field: 'service',
            headerName: 'Szolgáltatás',
            width: 200,
            renderCell: (params) => <Typography variant='button'>{params.row?.price?.service?.name}</Typography>,
            valueGetter: (params) => params.row?.price?.service?.name
        },
        {
            field: 'colleague',
            headerName: 'Kolléga',
            width: 130,
            renderCell: (params) => <Typography variant='caption'
                                                style={{color: '#536B74'}}>{params.row?.price?.colleague?.name}</Typography>,
            valueGetter: (params) => params.row?.price?.colleague?.name
        },
        {
            field: 'date',
            headerName: 'Időpont',
            type: 'dateTime',
            width: 130,
            renderCell: renderDate,
            valueGetter: (params) => `${params.row?.date} ${params.row?.start_time}`
        },
        {
            field: 'price',
            headerName: 'Ár',
            width: 130,
            type: 'number',
            renderCell: (params) => <Typography variant='button'
                                                style={{color: '#536B74'}}>{thousandSeparator(params.row?.price?.price) + ' Ft'}</Typography>,
            valueGetter: (params) => params.row?.price?.price
        },
        {
            field: 'manage',
            sortable: false,
            type: "actions",
            width: 200,
            // renderCell: params => <Stack direction='row'>
            //     <CircleButton icon={<DeleteIcon/>}/>
            //     <CircleButton icon={<DeleteIcon/>} inverted/>
            // </Stack>
            getActions: (params) => [
                <GridActionsCellItem
                    icon={<DeleteIcon style={iconStyle}/>}
                    onClick={() => {
                        setReservation(findReservation(params.row?.id))
                        setDeleteOpen(true)
                    }}
                    label="Törlés"
                    showInMenu={false}/>,
                <GridActionsCellItem
                    icon={<KeyboardArrowRightRoundedIcon style={invertedIconStyle}/>}
                    onClick={() => {
                        setReservation(findReservation(params.row?.id))
                        setDetailView(true)
                    }}
                    label="Részletek"
                    showInMenu={false}/>]
        },
    ]

    const dialogActions = <>
        <Button variant='outlined' style={{padding: 15}} onClick={() => setDeleteOpen(false)}>mégsem</Button>
        <Button variant='contained' style={{padding: 15}} onClick={() => {
            setDeleteOpen(false)
            deleteReservation(reservation?.id)
        }} endIcon={<DeleteIcon/>}>Foglalás törlése</Button>
    </>

    const modifyActions = <>
        <Button variant='outlined' style={{padding: 15}} onClick={() => setModifyTimeOpen(false)}>mégsem</Button>
        <Button variant='contained' style={{padding: 15}} onClick={() => {
            setModifyTrigger((modifyTrigger) => modifyTrigger + 1)
            setModifyTimeOpen(false)
        }} endIcon={<CalendarTodayRoundedIcon/>}>Időpont módosítása</Button>
    </>

    return <AuthBase label={`Üdv, ${user.name}!`}>
        <div className='main'>
            <Grid container spacing={2} style={{marginBottom: '20px'}}>
                <Grid item xs={12} md={6}>
                    <div className='dashboard-card'>
                        <Typography variant='headline6'>Szolgáltatások</Typography>
                        <Typography variant='body1' color='#536B74' marginBottom={3}>Az alábbi diagramon látszódank az
                            eddig beérkezett foglalások szolgáltatások szerint.</Typography>
                        <Doughnut data={data} style={{maxHeight: '80%'}}/>
                    </div>
                </Grid>
                <Grid item xs={12} md={6}>
                    <div className='dashboard-card'>
                        <Typography variant='headline6'>Foglalások</Typography>
                        <Typography variant='body1' color='#536B74' marginBottom={3}>Foglalások havi
                            bontásban.</Typography>
                        <Bar options={options} data={data2}/>
                    </div>
                </Grid>
            </Grid>
            <Typography variant='headline6'>Legfrissebb foglalások</Typography>
            <Typography variant='body1' color='#536B74' gutterBottom>Foglalások időrendben.</Typography>
            <DinoDataGrid columns={columns} rows={reservations}/>
        </div>
        <DetailDrawer open={detailView} setOpen={setDetailView}>
            <ReservationDetail
                r={reservation}
                close={() => setDetailView(false)}
                accept={accept}
                destroy={() => setDeleteOpen(true)}
                decline={reject}
                modify={() => {
                    setModifyTimeOpen(true)
                }}
                create_invoice={() => create_invoice(reservation?.id)}
                download_invoice={() => download_invoice(reservation?.id)}
                send_invoice={() => send_invoice(reservation?.id)}/>
        </DetailDrawer>

        <DinoDialog title='Biztos törölni szeretnéd az alábbi foglalást?'
                    subtitle='Csak abban az esetben törölj időpontot ha  azt a klienssel előre leegyeztetted!'
                    open={deleteOpen}
                    handleClose={() => setDeleteOpen(false)}
                    actions={dialogActions}>
            <DialogDetail r={reservation}/>
        </DinoDialog>

        <DinoDialog open={newReservationOpen}
                    title='Új foglalás hozzáadása'
                    subtitle=''
                    handleClose={() => setNewReservationOpen(false)}>
            <ReservationProvider setNewReservationOpen={setNewReservationOpen}
                                 reservations={reservations}
                                 setReservations={setReservations}>
                <ReserveBase/>
            </ReservationProvider>
        </DinoDialog>

        <DinoDialog open={modifyTimeOpen}
                    title='Időpont módosítása'
                    subtitle='Válassz időpontot!'
                    actions={modifyActions}
                    handleClose={() => setModifyTimeOpen(false)}>
            <SelectOnlyDate reservationData={reservation} modifyTrigger={modifyTrigger} updateDate={updateDate}/>
        </DinoDialog>

        <Loading isLoading={loading}/>
    </AuthBase>
}

export default Dashboard