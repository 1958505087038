import AuthBase from "../AuthBase";
import {useContext, useEffect, useState} from "react";
import {useHistory, useParams} from "react-router-dom";
import FileUploadRoundedIcon from '@mui/icons-material/FileUploadRounded';
import {
    Avatar,
    FormControlLabel,
    InputAdornment,
    Stack,
    Switch,
    useTheme
} from "@mui/material";
import Masonry from '@mui/lab/Masonry';
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import SaveRoundedIcon from '@mui/icons-material/SaveRounded';
import ConfigContext from "../../context/ConfigContext";
import businessTemplate from "../website/BusinessTemplate";
import useCallDataApi from "../../hooks/data";
import {isUnderMaxFileSize} from "../../utils/ValidationUtils";
import Loading from "../../components/Loading";
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import RemoveRoundedIcon from '@mui/icons-material/RemoveRounded';
import ExtraItemTemplate from "./ExtraItemTemplate";
import ErrorHandlingTextField, {
    validateNumber,
    validateRequired
} from "../../components/ErrorHandlingTextField";
import {truncateText} from "../../utils/FormUtils";


const ExtraItemDetail = () => {
    const theme = useTheme()
    const history = useHistory()
    const {baseUrl, MAX_IMG_SIZE} = useContext(ConfigContext)

    const [business, setBusiness] = useState(businessTemplate)
    const [canSubmit, setCanSubmit] = useState(false)
    const [extraItem, setExtraItem] = useState(ExtraItemTemplate)
    const [services, setServices] = useState([])
    const [editedExtraItem, setEditedExtraItem] = useState(ExtraItemTemplate)
    const [extraItemImage, setExtraItemImage] = useState('')
    const [errors, setErrors] = useState({
        name: false,
        description: false
    })
    const [loading, setLoading] = useState(true)


    const params = useParams()

    const {getData: fetchExtraItem, postData: postExtraItem, formDataUpdateData: updateExtraItem} = useCallDataApi('extra-items')
    const {getData: fetchService} = useCallDataApi('service')
    const {getData: fetchBusiness} = useCallDataApi('business')


    useEffect(() => {
        const getData = async () => {
            const [b, e, s] = await Promise.all([
                fetchBusiness('get_business/'),
                fetchExtraItem(params.id),
                fetchService('get_for_business/')
            ])
            setExtraItem(e)
            setEditedExtraItem(e)
            setBusiness(b)
            setServices(s)
            setLoading(false)
        }
        getData().catch(r => console.log(r))
    }, [])

    const isChanged = () => JSON.stringify(extraItem) !== JSON.stringify(editedExtraItem)
    const noErrors = () => Object.values(errors).every(e => !e)

    useEffect(() => {
        setCanSubmit(isChanged() && noErrors())
    }, [errors, editedExtraItem, extraItem])

    const editExtraItem = () => {
        if (!(editedExtraItem?.image instanceof File)) delete editedExtraItem['image']
        console.log('sending', editedExtraItem)
        updateExtraItem(extraItem?.id, editedExtraItem).then(s => {
            setExtraItem(s)
            setEditedExtraItem(s)
        })
    }

    const buttons = [{
        name: 'Változtatások mentése',
        props: {
            variant: 'contained',
            disabled: !canSubmit,
            endIcon: <SaveRoundedIcon/>,
            onClick: () => editExtraItem()
        }
    }]

    const buttonsMobile = [{
        name: 'Mentés',
        props: {
            variant: 'contained',
            size: 'small',
            disabled: !canSubmit,
            endIcon: <SaveRoundedIcon/>,
            onClick: () => editExtraItem()
        }
    }]

    const getExtraItemImage = () => {
        if (extraItemImage instanceof File) return URL.createObjectURL(extraItemImage)
        if (extraItem?.image.startsWith('http')) return extraItem.image
        return baseUrl + extraItem?.image;
    }

    const addService = (s) => {
        setLoading(true)
        postExtraItem('add_service/', {id: extraItem?.id, service: s?.id}).then(s => {
            setEditedExtraItem({...editedExtraItem, services: s})
            setExtraItem({...extraItem, services: s})
        }).finally(() => setLoading(false))
    }

    const removeService = (s) => {
        setLoading(true)
        postExtraItem('remove_service/', {id: extraItem?.id, service: s?.id}).then(s => {
            setEditedExtraItem({...editedExtraItem, services: s})
            setExtraItem({...extraItem, services: s})
        }).finally(() => setLoading(false))
    }

    return <AuthBase label={extraItem?.name} buttons={buttons} filterButtonsMobile={buttonsMobile}>
        <div className="main">
            <Button variant='text' startIcon={<ArrowBackIosNewRoundedIcon/>}
                    onClick={() => history.push('/extra-items')}>vissza</Button>
            <Masonry columns={{xs: 1, sm: 1, md: 2, xl: 2}} spacing={2} sx={{margin: 0}}>
                {/*General data*/}
                <div className="white-card">
                    <Typography variant='subtitle1'>Általános adatok</Typography>
                    <Typography variant='body2' color='#536B74'>Add meg a tétel nevét és leírását!</Typography>
                    <Stack spacing={1} marginTop={2} marginBottom={2}>
                        <Typography variant='caption' color={theme.palette.primary[500]}>Tétel neve*</Typography>
                        <ErrorHandlingTextField value={editedExtraItem?.name} validators={[validateRequired]}
                                                onValChange={event => setEditedExtraItem({
                                                    ...editedExtraItem,
                                                    name: event.target.value
                                                })}
                                                check={(v) => setErrors({...errors, name: v})}
                                                name="name"
                                                variant='standard'/>
                        <Typography variant='caption' color={theme.palette.primary[500]}>Tétel rövid leírása*</Typography>
                        <ErrorHandlingTextField value={editedExtraItem?.description} validators={[validateRequired]}
                                                onValChange={event => setEditedExtraItem({
                                                    ...editedExtraItem,
                                                    description: event.target.value
                                                })}
                                                check={(v) => setErrors({...errors, description: v})}
                                                name="description"
                                                variant='standard'/>
                        <Typography variant='caption' color={theme.palette.primary[500]}>Ár*</Typography>
                        <ErrorHandlingTextField value={editedExtraItem?.price} validators={[validateRequired, validateNumber]}
                                                onValChange={event => setEditedExtraItem({
                                                    ...editedExtraItem,
                                                    price: event.target.value
                                                })}
                                                InputProps={{
                                                    endAdornment: <InputAdornment position="start">Ft</InputAdornment>,
                                                }}
                                                check={(v) => setErrors({...errors, price: v})}
                                                name="price"
                                                variant='standard'/>
                    </Stack>
                </div>

                {/*/!*Visibility*!/*/}
                <div className="white-card">
                    <Typography variant='subtitle1'>Láthatóság</Typography>
                    <Typography variant='body2' color='#536B74'>Ha nem szeretnéd, hogy a tétel látható legyen
                        a kliensek számára akkor kikapcsolhatod. (Az elrejtés előtt leadott foglalások nem fognak
                        törlődni!)</Typography>
                    <FormControlLabel
                        control={<Switch sx={{m: 1}} checked={editedExtraItem?.is_public}
                                         onChange={(event) => setEditedExtraItem({
                                             ...editedExtraItem,
                                             is_public: event.target.checked
                                         })}/>}
                        label={<Typography variant='caption' color='#1C2427'>Látható a kliensek
                            számára</Typography>}
                    />
                </div>

                {/*/!*Picture*!/*/}
                <div className="white-card">
                    <Typography variant='subtitle1'>Tétel képe</Typography>
                    <Typography variant='body2' color='#536B74'>Tölts fel egy a tételre jellemző
                        képet!</Typography>
                    <Stack direction='row' spacing={2} alignItems='center' marginTop={2}>
                        <Avatar src={getExtraItemImage()} sx={{
                            width: 80,
                            height: 80,
                            border: '2px solid',
                            borderColor: theme.palette.primary[500],
                            background: theme.palette.primary[50]
                        }}/>
                        <Button variant='outlined' component='label' endIcon={<FileUploadRoundedIcon/>}>Kép
                            feltöltése<input type="file" accept='image/*' hidden onChange={event => {
                                const file = event.target.files[0];
                                if (isUnderMaxFileSize(file, MAX_IMG_SIZE)) {
                                    setExtraItemImage(file)
                                    setEditedExtraItem({...editedExtraItem, image: file})
                                } else alert(`A kép nem lehet nagyobb mint ${MAX_IMG_SIZE} MB`)
                            }}/></Button>
                    </Stack>
                </div>

                {/*Services*/}
                <div className="white-card">
                    <Typography variant='subtitle1'>Szolgáltatások</Typography>
                    <Typography variant='body2' color='#536B74'>Vedd fel azokat a szolgáltatásokat ahol szeretnéd, hogy elérhető legyen a tétel!</Typography>
                    <Stack spacing={2} marginTop={2}>
                        {services.map((s, i) => <Stack direction='row' key={`price_${i}`} justifyContent="space-between">
                            <Stack direction='row' spacing={2} alignItems='center'>
                                <Avatar src={baseUrl + s?.image}
                                        sx={{width: '48px', height: '48px'}}/>
                                <Stack>
                                    <Typography variant='subtitle2'>{s.name}</Typography>
                                    <Typography variant='caption'
                                                color='#536B74'>{truncateText(s.description, 150)}</Typography>
                                </Stack>
                            </Stack>
                            <Stack direction='row' spacing={1} alignItems='center'>
                                {editedExtraItem?.services?.includes(s?.id) ?
                                    <Button variant='outlined' endIcon={<RemoveRoundedIcon/>} onClick={() => removeService(s)}>Eltávolítás</Button>
                                    :
                                    <Button variant='contained' endIcon={<AddRoundedIcon/>} onClick={() => addService(s)}>Hozzáadás</Button>
                                }
                            </Stack>
                        </Stack>)}
                    </Stack>

                </div>

            </Masonry>

        </div>

        <Loading isLoading={loading}/>
    </AuthBase>

}

export default ExtraItemDetail